<script setup lang="ts">
import { FRONTEND_CONTENT_TYPES, type FrontContent, type Hub, type HubSettings, type OverviewContentType, type Pagination } from "@gasparigit/omnia-sdk";
import type { RouteLocationAsPathGeneric, RouteLocationAsRelativeGeneric } from "vue-router";
import { FRONT_TYPES_MAP } from "~/lib/const";
import { cn } from "~/lib/utils";
import type { CreateLinkConfig } from "~/utils/types";

const data = defineModel<OverviewContentType>();
const highlighted = defineModel<Pagination<FrontContent> | null>("highlighted");

const props = withDefaults(
  defineProps<{
    hub?: Hub;
    linkConfig?: CreateLinkConfig;
    settings?: HubSettings;
    openReadAll?: (frontType: string) => void;
    readAllTo?: (frontType: string) => string | RouteLocationAsRelativeGeneric | RouteLocationAsPathGeneric | undefined;
    transparent?: boolean;
  }>(),
  {
    transparent: false,
    settings: () => Object.values(FRONT_TYPES_MAP) as HubSettings,
  }
);

const filteredSettings = computed(() => {
  return props.settings.filter((tab) => {
    if (!data.value) return false;

    return FRONT_TYPES_MAP[tab.type].show_in_all && tab.type in data.value && data.value[tab.type].length;
  });
});
</script>

<template>
  <template v-if="data">
    <div v-if="highlighted?.data.length" class="break-inside-avoid-column mt-16">
      <h2 class="flex items-center text-4xl font-extrabold mb-5 pb-2">
        <IconsFlag class="text-muted-foreground w-12 h-12 flex-none me-2" />
        <span>In evidenza</span>
      </h2>

      <div class="grid lg:grid-cols-2 gap-5 lg:gap-16">
        <FrontContentItem
          :highlight="true"
          :link-config="linkConfig"
          :show-children="true"
          :content="content"
          :show-areas="false"
          v-for="content in highlighted.data"
          :key="`highlighted-${content.id}`"
        />
      </div>
    </div>
    <MasonryWall class="mt-16 mb-10" :items="filteredSettings" :gap="64" :min-columns="1" :max-columns="2">
      <template #default="{ item: tab }">
        <div :class="cn('break-inside-avoid-column', FRONT_TYPES_MAP[tab.type].hubAllCardClass, transparent && 'bg-transparent shadow-none')">
          <h2 class="flex items-center text-4xl font-extrabold mb-5 pb-2 border-b">
            <component :is="FRONT_TYPES_MAP[tab.type].icon" class="text-muted-foreground w-12 h-12 flex-none me-2" />
            <span>{{ FRONTEND_CONTENT_TYPES[tab.type] }}</span>
          </h2>
          <div class="flex flex-col gap-y-6">
            <FrontContentItem
              :link-config="linkConfig"
              :show-children="tab.type !== 'pratiche' || data.pratiche.length == 1"
              :content="content"
              :show-areas="false"
              v-for="content in data[tab.type]"
              :key="`all-${content.id}`"
            />
            <div class="text-right" v-if="FRONT_TYPES_MAP[tab.type].show_in_tabs && readAllTo">
              <NuxtLink @click="openReadAll && openReadAll(tab.type)" :to="readAllTo(tab.type)" title="Vedi tutto" class="link">Vedi tutto</NuxtLink>
            </div>
          </div>
        </div>
      </template>
    </MasonryWall>
  </template>
  <Skeleton v-else class="w-full h-52 rounded-lg" />
</template>
